import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['templateChooserFilterValues']),
    isActiveFilters() {
      return this.filter.types.length > 0 || this.filter.goals.length > 0;
    },
  },
  methods: {
    getPossibleTemplates() {
      throw new Error('Implement this method');
    },
  },
};
