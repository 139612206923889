<template lang="pug">
.template-chooser-home-wrapper
  TopFilter(
    v-if="!isFilterActive && !search"
    @messageTypeFilter="$emit('messageTypeFilter', $event)"
    @imagePopup="$emit('createCampaign', 'flyer')"
  )
  Skeleton(v-if="blocksLoading")
  ListEmptyState(
    v-else-if="!hasResults"
    :image="require('@/assets/admin/svg/monk_magnifying.svg')"
    :title="$t('templateChooser.emptyStateBaseTitle')"
  )
  component.block(
    v-for="(block, index) in blocks"
    ref="blocks"
    :is="block"
    :key="block"
    :themes="themes"
    :filter="filter"
    :search="search"
    :useCaseMap="useCaseMap"
    @loaded="onComponentLoaded"
    @navigateWithFilterReset="$emit('navigateWithFilterReset', $event)"
    @navigateWithFilter="$emit('navigateWithFilter', $event)"
    @createDynamicContent="createDynamicContent()"
    @track="$emit('trackActivity', $event)"
    @templateCount="onTemplateCount"
  )
</template>

<script>
  import { track } from '@/services/xray';
  import { mapGetters } from 'vuex';
  import { debounce } from 'lodash-es';
  import childRouteMixin from '../mixins/childRoute';
  import filterHelperMixin from '../mixins/filterHelper';

  const PAGE_PERSONALIZATION_TYPE = 'page';
  const AB_TEST_PERSONALIZATION_TYPE = 'abTest';

  const BLOCK_UPCOMING = 'upcoming-seasons';
  const BLOCK_MORE_TEMPLATES = 'more-templates';
  const BLOCK_THEMES = 'themes';
  const BLOCK_YOUR_THEMES = 'your-themes';
  const BLOCK_ADVANCED_TACTIC_RECOMMENDATION = 'advanced-tactics-recommendations';

  export default {
    components: {
      Recommendations: () =>
        import('@/components/TemplateChooser/components/blocks/Recommendations.vue'),
      [BLOCK_YOUR_THEMES]: () =>
        import('@/components/TemplateChooser/components/blocks/YourThemes.vue'),
      [BLOCK_UPCOMING]: () =>
        import('@/components/TemplateChooser/components/blocks/UpcomingSeasons.vue'),
      [BLOCK_THEMES]: () => import('@/components/TemplateChooser/components/blocks/Themes.vue'),
      [BLOCK_MORE_TEMPLATES]: () =>
        import('@/components/TemplateChooser/components/blocks/MoreTemplates.vue'),
      [BLOCK_ADVANCED_TACTIC_RECOMMENDATION]: () =>
        import('@/components/TemplateChooser/components/blocks/AdvancedTacticsRecommendations.vue'),
      TopFilter: () => import('@/components/TemplateChooser/components/TopFilter.vue'),
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
      ListEmptyState: () => import('@/components/TemplateChooser/components/ListEmptyState.vue'),
    },
    mixins: [childRouteMixin, filterHelperMixin],
    data() {
      return {
        PAGE_PERSONALIZATION_TYPE,
        AB_TEST_PERSONALIZATION_TYPE,
        hasResults: true,
        blocksLoading: true,
        accumulatedCount: 0,
        typeCounts: {},
      };
    },
    computed: {
      ...mapGetters(['getLocale']),
      isFilterActive() {
        return this.isActiveFilters || this.search;
      },
      blocks() {
        const blocks = [];
        if (!this.isFilterActive) {
          blocks.push('recommendations');
        } else if (this.themes?.user?.length) {
          blocks.push(BLOCK_YOUR_THEMES);
        }
        if (!this.search) blocks.push(BLOCK_UPCOMING);
        if (!this.isFilterActive) {
          blocks.push(BLOCK_ADVANCED_TACTIC_RECOMMENDATION);
        }
        if (!this.search) {
          blocks.push(BLOCK_THEMES, BLOCK_MORE_TEMPLATES);
        } else {
          blocks.push(BLOCK_MORE_TEMPLATES);
        }
        return blocks;
      },
    },
    watch: {
      filter: {
        handler() {
          this.blocksLoading = true;
        },
        deep: true,
      },
      search() {
        this.blocksLoading = true;
      },
    },
    created() {
      this.debouncedTrack = debounce(this.onTrack, 1500);
    },
    mounted() {
      this.$emit('setLoading', false);
    },
    methods: {
      onTemplateCount({ type, count }) {
        if (!this.search) return;

        this.accumulatedCount += count;

        if (!this.typeCounts[type]) {
          this.typeCounts[type] = 0;
        }

        this.typeCounts[type] += count;
        this.debouncedTrack();
      },
      onTrack() {
        const typeCounts = Object.keys(this.typeCounts).map((type) => ({
          type,
          count: this.typeCounts[type],
        }));

        track('template_selector_click', {
          component: 'Search',
          setting: 'start',
          options: this.search,
          location: 'main',
          totalCount: this.accumulatedCount,
          typeCounts,
        });

        this.accumulatedCount = 0;
        this.typeCounts = {};
      },
      openLink(url) {
        window.open(url, '_blank').focus();
      },
      createDynamicContent(addControlVariant = false) {
        this.$modal.show('name-campaign', {
          canChange: false,
          isDynamicContent: true,
          addControlVariant,
        });
      },
      onComponentLoaded(child) {
        if (child) {
          this.hasResultsInBlocks();
        }
      },
      hasResultsInBlocks: debounce(function () {
        this.hasResults = this.$refs.blocks?.some?.((block) => {
          const possibleTemplates = block?.getPossibleTemplates?.() ?? [];
          return !!possibleTemplates?.length;
        });
        this.blocksLoading = false;
      }, 50),
    },
  };
</script>
<style lang="sass">
  .block
    margin-bottom: 3.75rem
</style>
